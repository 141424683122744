.footer-infobox {
  cursor: pointer;

  &-title {
    width: 100%;
    font-size: 20px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  //&-payload {
  //
  //}

  &-actions {
    margin-top: 16px;
  }
}
