.sandbox-page {
  padding: 20px;
  height: 100%;
  overflow: auto;

  &-title {
    font-size: 32px;
    margin-bottom: 32px;
    border-bottom: 1px solid #eaeaea;
  }

  &-section {
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;

    &__title {
      font-size: 24px;
      margin-bottom: 24px;
    }

    &__content {
      width: 100%;
    }
  }
}

.sandbox-line-list {
  display: flex;
  flex-wrap: wrap;
  margin: -4px;

  .sandbox-line-list__item {
    padding: 4px;
  }
}
