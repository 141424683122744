@import "mixins";

.hint {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: 0;
  color: var(--color-gray-30);

  &-highlight {
    color: var(--color-blue);
  }
}

.hint-neg-half-up {
  @extend .hint;
  margin-top: -8px;
}

.hint-half-up {
  @extend .hint;
  margin-top: 8px;
}

.hint-full-up {
  @extend .hint;
  margin-top: 16px;
}

.font-weight700 {
  font-weight: 700;
}

.white-space-pre {
  white-space: pre-wrap;
}
.app-error {
  font-size: 14px;
  color: #E62E4D;
  margin-top: 8px;
}
.indented {
  margin: 16px 0;
}
.heading {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-top: 8px;
  margin-bottom: 16px;

  @include screen-tablet {
    margin-top: 32px;
  }
}
.subheading {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
}
