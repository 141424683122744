@import "mixins";

.app-control {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  justify-content: center;
  position: relative;
  padding: 8px 0;

  &.inline-control {
    padding: 0;
  }

  @include screen-tablet {
    padding: 12px 0;
  }

  &-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 8px;
    text-align: left;

    &.error {
      color: var(--color-red);
    }

    @include screen-tablet {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &-field {
    box-sizing: border-box;
    display: flex;
    height: 48px;
    align-items: center;
    border-radius: 12px;
    border: 1px solid #DFE2EB;
    padding: 12px 16px;
    background: #ffffff;

    @include screen-tablet {
      font-size: 16px;
      line-height: 24px;
      padding: 16px;
    }

    &:focus-within {
      border-color: #0095FF;
      outline: 1px solid #0095FF;
    }

    &.select-opened {
      border-color: #0095FF;
      outline: 1px solid #0095FF;
    }

    &.error {
      border-color: var(--color-red);
      outline: 1px solid var(--color-red);
    }

    &__input {
      width: 100%;
      border: none;
      outline: none;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      // Placeholder
      // *********************
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      &::placeholder {
        color: #606A7D;
        /* Firefox */
        opacity: 1;
      }

      /* Internet Explorer 10-11 */
      &:-ms-input-placeholder {
        color: #606A7D;
      }

      /* Microsoft Edge */
      &::-ms-input-placeholder {
        color: #606A7D;
      }
    }

    &__textarea {
      width: 100%;
      height: auto;
      border: none;
      outline: none;
      resize: none;
      box-sizing: border-box;

      // Placeholder
      // *********************
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      &::placeholder {
        color: #606A7D;
        /* Firefox */
        opacity: 1;
        height: 30px;
        line-height: 30px;
        overflow: hidden;

        @include screen-tablet {
          color: #606A7D;
          /* Firefox */
          opacity: 1;
          height: 35px;
          line-height: 35px;
          overflow: hidden;
        }
      }

      /* Internet Explorer 10-11 */
      &:-ms-input-placeholder {
        color: #606A7D;
        height: 30px;
        line-height: 30px;
        overflow: hidden;

        @include screen-tablet {
          color: #606A7D;
          height: 35px;
          line-height: 35px;
          overflow: hidden;
        }
      }

      /* Microsoft Edge */
      &::-ms-input-placeholder {
        color: #606A7D;
        height: 30px;
        line-height: 30px;
        overflow: hidden;

        @include screen-tablet {
          color: #606A7D;
          height: 35px;
          line-height: 35px;
          overflow: hidden;
        }
      }
    }

    &__info {
      font-size: 20px;
      padding: 2px;
      color: var(--color-gray-30);
      //z-index: 1 !important;
    }

    &__info.disabled-info {
      pointer-events: auto;
    }

    // Prefix / Suffix
    // *********************

    &__suffix:empty,
    &__prefix:empty {
      display: none;
    }

    &__prefix,
    &__suffix {
      font-size: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__prefix {
      margin-right: 12px;
    }

    &__suffix {
      margin-left: 12px;
      color: var(--color-gray-40);
      cursor: pointer;

      &_drop {
        font-size: 12px;
        margin-left: auto;
      }
    }

    &__calendar {
      width: 56px;
      height: 56px;
      padding: 16px;
      // padding: 4px 16px 0 0;
      text-align: center;
      color: var(--color-blue);
      cursor: pointer;
      position: relative;

      .icon-calendar-empty {
        font-size: 24px;
      }

      &:after {
        content: "";
        position: absolute;
        background: #DFE2EB;
        height: 24px;
        right: 0;
        top: 16px;
        width: 1px;
      }
    }

    &__border {
      width: 1px;
      height: 24px;
      flex-shrink: 0;

      padding-right: 16px;
      color: var(--color-fog-40);
    }

    &_amounts {
      display: flex;
      width: 100%;
      height: 20px;
      justify-content: right;
      align-items: center;

      &__block {
        &_title {
          display: flex;
          align-items: center;

          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          color: var(--color-blue);

          &-gap {
            display: block;
            padding: 0 8px;
            height: 20px;
            line-height: 17px;
            color: var(--color-fog-40);
          }
        }
      }
    }

    &__line {
      height: 24px;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        background: #DFE2EB;
        height: 24px;
        right: 0;
        top: 0;
        width: 1px;
      }
    }

    @include screen-tablet {
      min-height: 56px;
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.6;
  }

  &_list {
    display: flex;
    width: 551px;
    padding: 16px 16px;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 2px;
    gap: 8px;

    position: absolute;
    list-style-type: none;
    overflow-y: auto;
    top: 100%;

    border-radius: 12px;
    border: 1px solid var(--color-fog-40);
    background: var(--color-white);
    box-shadow: 0 3px 12px 5px rgba(155, 155, 159, 0.15);

    &__title {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      color: var(--color-black);
    }

    &__item {
      display: flex;
      min-width: 500px;
      height: 88px;
      align-items: center;
      gap: 16px;

      &_icon {
        width: 48px;
        height: 48px;
        padding: 12px;
        background: var(--color-orange-light);
        border-radius: 28px;

        .icon-coins {
          font-size: 24px;
          color: var(--color-orange);
        }
      }

      &_text {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: var(--color-black);
      }

      &_underline {
        height: 1px;
        margin-left: 64px;
        background: var(--color-fog-40);
      }
    }
  }

  &_empty {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;

    &__text {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: var(--color-gray-30);
    }
  }
}

.app-control[data-size="1"] {
  height: 112px;

  @include screen-tablet {
    height: 120px;
  }
}

.invisible.app-control-field__textarea {
  visibility: hidden;
}
