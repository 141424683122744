a {
  transition: color 128ms;

  &:hover {
    color: #3752DB;
  }
}

.active-link {
  color: #3752DB;
  outline: none;
}

.active-link_error {
  color: var(--color-red);
  outline: none;
}

.tooltip {
  a {
    color: var(--color-blue-light);
  }
}
