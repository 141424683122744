@import "mixins";

.app-tabs {
  display: flex;
  flex-direction: column;

  &-list {
    display: flex;
    // padding: 0 16px;
    border-bottom: 1px solid #DFE2EB;
    overflow-x: scroll;

    @include screen-tablet {
      overflow-x: visible;
    }

    &__item {
      font-size: 14px;
      padding: 0 0 12px;
      margin-left: 24px;
      cursor: pointer;
      color: #606A7D;
      line-height: 20px;

      &.active {
        border-bottom: 4px solid #3752DB;
        color: var(--color-black);
      }

      &:first-child {
        margin-left: 0;
      }

      @include screen-tablet {
        padding-top: 0;
        font-size: initial;
        line-height: 24px;
        margin-left: 32px;
      }
    }

    &.left {
      justify-content: flex-start;
    }

    &.center {
      justify-content: center;
    }

    &.right {
      justify-content: flex-end;
    }

    &.mr {
      margin: 0 16px;

      @include screen-tablet {
        margin: 0;
      }
    }
  }

  &-payload {
    //margin-top: 8px;

    &.mr {
      margin: 0 16px;

      @include screen-tablet {
        margin: 0;
      }
    }

    &.mr-0 {
      margin: 0;
    }
  }
}


.hidden-tabs {
  margin-top: 0;
  .app-tabs-list {
    display: none;
  }
}

.app-tabs-list-mr {
  margin: 16px 16px 0 16px;
}

.app-tabs-list::-webkit-scrollbar {
  width: 0;
}

.app-tabs-list::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.app-tabs-list::-webkit-scrollbar-track {
  background-color: transparent;
}

/* firefox */
.app-tabs-list {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  scrollbar-gutter: stable;
}
