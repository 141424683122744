// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$palette-primary: mat.define-palette($md-primary, 500, 100, 900);
$palette-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$palette-warn: mat.define-palette($md-warn);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$palette-theme-typography: mat.define-typography-config(
  $font-family: var(--font-family-primary),
  $button: mat.define-typography-level($font-size: var(--button-font-size)),
);

$palette-theme-light: mat.define-light-theme((
  color: (
    primary: $palette-primary,
    accent: $palette-accent,
    warn: $palette-warn,
  )
));

$palette-theme-dark: mat.define-dark-theme((
  color: (
    primary: $palette-primary,
    accent: $palette-accent,
    warn: $palette-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-typographies($palette-theme-typography);

//@include mat.all-component-themes($palette-theme-light);

.theme {
  &.theme-light {
    @include mat.all-component-colors($palette-theme-light);
  }

  &.theme-dark {
    @include mat.all-component-colors($palette-theme-dark);
  }
}
