:root {
  // Colors
  // **********************
  // **********************
  --color-black: #000000;
  --color-carbon: #333333;
  --color-achromatic: #3F414C;
  --color-achromatic-gray: #4F6694;
  --color-gray: #999999;
  --color-gray-20: #4E596E;
  --color-gray-30: #606A7D;
  --color-gray-40: #8B95A9;

  --color-fog-40: #DFE2EB;
  --color-fog-50: #EDEFF2;
  --color-fog-75: #F7F8FA;

  --color-white: #FFFFFF;
  --color-transparent: transparent;

  --color-blue-dark: #212AC4;
  --color-blue-dark-light: #EBEEFF;
  --color-blue: #3752DB;
  --color-blue-light: #0095FF;
  --color-blue-sky: #9AA9F4;
  --color-blue-sky-light: #E5F4FF;

  --color-green-dark: #1C8743;
  --color-green-accept: #3EC764;
  --color-green-default: #31A764;
  --color-green: #22CC52;
  --color-green-light: #7AE097;
  --color-green-aqua: #24CCCC;

  --color-red-dark: #A62C21;
  --color-red: #E62E4D;
  --color-red-light: #F08294;

  --color-rose: #FFEFF5;

  --color-orange: #FF8800;
  --color-orange-light: #FFF1E0;

  --color-yellow: #FFBB33;
  --color-yellow-light: #FFD685;

  --color-brand: var(--color-blue);
  --color-logo: #0853AD;

  // **********************
  // **********************

  // General
  --font-family-primary: 'Golos Text', sans-serif;
  --font-weight: 400;
  --font-size: 16px;
  --font-color: var(--color-black);
  --font-line-height: 1.5;

  --background-body: var(--color-white);

  // Button
  --button-font-size: 16px;
  --button-height: 56px;
  --button-border-radius: 12px;

  // Input
  --input-autofill-background: #ffffff;
  --input-autofill-text-color: #000000;

  // Checkbox
  --checkbox-border-radius: 4px;
  --checkbox-border-color-default: var(--color-fog-40);
  --checkbox-border-color-hover: var(--color-gray-40);
  --checkbox-border-color-disabled: var(--color-fog-50);

  --checkbox-size: 24px;
  --checkbox-color-default: var(--color-blue);
  --checkbox-color-hover: var(--color-blue-dark);
  --checkbox-color-disabled: var(--color-blue-sky);

  --checkbox-arrow-color: var(--color-white);
  --checkbox-arrow-size: 10px;

  // Sidebar
  --sidebar-background: var(--color-fog-75);
  --sidebar-tablet-br: 12px;

  // Navigation
  --nav-item-height-xl: 48px;
  --nav-item-height-md: 56px;
  --nav-item-width: 68px;
  --nav-item-icon-color: var(--color-gray-40);
  --nav-item-icon-size: 20px;
  --nav-item-title-color: var(--color-gray-30);
  --nav-item-title-size: 12px;
  --nav-item-active-bg: var(--color-white);
  --nav-item-active-color: var(--color-black);
  --nav-item-border-radius: 8px;
}

.theme-dark {
  --background-body: #424242;

  --sidebar-background: var(--color-fog-75);

  --font-color: #f4f4f4
}
