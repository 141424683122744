@import "mixins";

.widget-product {
  background: #041333;
  border-radius: 16px;
  padding: 16px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  // margin:  0 -8px 16px;

  @include screen-tablet {
    padding: 24px;
    // margin: 0 0 48px;
  }

  &_bg-1 {
    background: url("/assets/images/products/bg/bg-1.png") no-repeat;
    background-size: cover;
  }

  &_bg-2 {
    background: url("/assets/images/products/bg/bg-2.png") no-repeat;
    background-size: cover;
  }

  &-head {
    &-info {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-title {
        opacity: 0.5;
      }

      &-preview {
        margin-left: auto;
      }
    }

    &-amount {
      margin-top: 4px;
      display: flex;
      align-items: center;
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;

      &_card {
        padding: 4px 0;
        margin-top: 0;
        margin-bottom: 16px;
      }

      @include screen-tablet {
        font-size: 32px;
        line-height: 40px;
      }

      &__value {
        font-weight: 700;

        &_decimal {
          opacity: 0.5;
        }
      }

      &__currency {
        opacity: 0.5;
        margin-left: 8px;
      }

      &__reload {
        font-size: 20px;
        margin-left: 8px;
      }
    }

    &-hide-amount {
      display: flex;
      align-items: center;
      font-size: 32px;
      height: 32px;

      &_dots {
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;
        position: relative;
        display: inline-block;

        width: 8px;
        height: 8px;
        margin-right: 6px;
        border-radius: 50%;
        background-color: var(--color-white);

        &::before {
          content: "";
          position: absolute;
          width: 7px;
          height: 7px;
          border-radius: 50%;
          background-color: var(--color-white);
          top: 2px;
          left: 2px;
        }
      }
    }
  }

  &-payload {
    margin-top: auto;
  }

  &-actions {
    display: flex;
    margin-top: 16px;
    gap: 8px;

    @include screen-tablet {
      padding-top: 24px;
      margin-top: 24px;

      &:not(.no-border) {
        border-top: 1px solid #ffffff30;
      }
    }
  }
}

.widget-product[data-mr-size="1"] {
  margin:  0 -8px 16px;

  @include screen-tablet {
    margin: 0 0 48px;
  }
}

.widget-product[data-mr-size="2"] {
  margin:  0 8px 16px;

  @include screen-tablet {
    margin: 0 -8px 48px;
  }
}

.product-card-info {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include screen-tablet {
    margin-top: 44px;
    flex-direction: row;
    gap: 68px;
  }


  &-section {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
      opacity: 0.5;
    }

    @include screen-tablet {
      flex-direction: column;
      align-items: start;

      &__value {
        margin-top: 4px;
      }
    }
  }
}

.product-card-progress {
  margin: 32px 0 16px;

  @include screen-tablet {
    margin: 44px 0 0;
  }

  &-info {
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    &__value {
    }

    &__text {
      opacity: 0.6;
      padding: 0 4px;
    }

    &__from {
    }
  }

  &-control {
  }
}
