.container {
  max-width: 1440px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.flex-inline {
  display: inline-flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
