@import "mixins";

.dialog {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 16px;
  border-radius: 12px;
  background: #ffffff;
  width: 343px;
  gap: 24px;

  @include screen-tablet {
    width: 434px;
    padding: 32px;

    &_large {
      width: 624px;
    }
  }

  &-header {
    display: flex;
    flex-direction: column;
    position: relative;

    &__title {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      margin-right: 24px;

      @include screen-tablet {
        &_large {
          font-size: 32px;
          line-height: 40px;
        }
      }

      &_with-icon {
        text-align: center;
        margin-right: 0;
        font-size: 20px;
        line-height: 24px;
      }
    }

    &__icon {
      background: var(--color-green);
      width: 64px;
      height: 64px;
      border-radius: 50%;
      text-align: center;
      display: flex;
      font-size: 19px;
      color: white;
      align-items: center;
      justify-content: center;
      align-self: center;
      margin-bottom: 24px;
    }

    &__close {
      position: absolute;
      right: 0;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: 16px;
      line-height: 16px;
      color: var(--color-gray-30);
    }

    &__subtitle {
      margin-top: 16px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
    }
  }

  &-info {
    color: var(--color-gray-30);
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    @include screen-tablet {
      margin-bottom: 12px;
    }
  }

  &-payload {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    max-height: calc(100vh - 240px);
    overflow-y: auto;

    &_center {
      text-align: center;
    }

    &_gap {
      gap: 24px;
    }

    a {
      color: #3752DB;
    }

    &__field {
      display: flex;
      flex-direction: column;
      padding: 14px 0;
      gap: 4px;
      border-bottom: 1px solid var(--color-fog-40);

      &-title {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        color: var(--color-gray-30);
      }

      &-text {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
      }

      &_border-top {
        border-top: 1px solid var(--color-fog-40);
      }
    }
  }

  &-fifty {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 8px 0;

    @include screen-tablet {
      flex-direction: row;
      gap: 24px;
      padding: 12px 0;
    }
  }

  &-actions {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 8px;

    @include screen-tablet {
      gap: 16px;
    }

    &__item {
    }
  }
}

.dialog[data-size="1"] {
  width: auto;
  padding: 16px;
  max-width: 434px;

  @include screen-tablet {
    width: 624px;
    padding: 32px;
    max-width: 624px;
  }
}

/* Middleware popup */

.call-order-container {
  display: flex;
  text-align: center;
  flex-direction: column;
  border-radius: 12px;
  background: var(--color-white);
  overflow: hidden;
  max-height: 100vh;

  @include screen-tablet {
    width: 100%;
    min-width: 624px;
    display: flex;
    text-align: center;
    flex-direction: column;
    border-radius: 12px;
    background: var(--color-white);
    overflow: hidden;
    max-height: calc(100vh - 240px);
  }

  &_head {
    display: flex;
    width: 100%;
    padding: 32px 32px 0;
    align-items: center;
    justify-content: space-between;

    &__close {
      order: 2;
      padding-right: 16px;
      padding-top: 16px;
    }

    &__title {
      display: flex;
      font-size: 24px;
      line-height: 32px;
      font-weight: 600;
      text-align: left;
      order: 1;

      @include screen-tablet {
        display: flex;
        height: 32px;
        font-size: 32px;
        line-height: 40px;
        font-weight: 600;
        text-align: left;
        order: 1;
      }
    }
  }
}

/* Catalog Cards */

.address {
  display: flex;

  &-item {
    margin-left: 16px;
  }

  &-item:first-child {
    margin-left: 0;
  }
}

.card-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
  padding: 16px;
  background: #FFFFFF;

  &_title {
    display: flex;
    width: 100%;
    align-items: baseline;
    justify-content: space-between;
    padding-bottom: 24px;
    gap: 16px;

    &__text {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      color: var(--color-black);
      line-height: 24px;

      @include screen-tablet {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        color: var(--color-black);
        line-height: 32px;
      }
    }

    &__icon {
      font-size: 16px;
      line-height: 16px;
      cursor: pointer;

      .icon-cross {
        color: var(--color-gray-30);
      }
    }
  }

  @include screen-tablet {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 624px;
    padding: 32px;
    background: #FFFFFF;
  }

  &_container {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &__title {
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      color: var(--color-gray-30);
    }

    &__text {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }

    &_underline {
      height: 1px;
      width: 100%;
      background: var(--color-fog-40);
      margin: 14px 0 18px 0;
    }
  }
}

.card-address {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
  padding: 16px;
  background: #FFFFFF;

  &_title {
    display: flex;
    width: 100%;
    align-items: baseline;
    justify-content: space-between;
    padding-bottom: 24px;
    gap: 16px;

    &__text {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      color: var(--color-black);
      line-height: 24px;

      @include screen-tablet {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        color: var(--color-black);
        line-height: 32px;
      }
    }

    &__icon {
      font-size: 16px;
      line-height: 16px;
      cursor: pointer;

      .icon-cross {
        color: var(--color-gray-30);
      }
    }
  }

  &_container {
    display: flex;
    flex-direction: column;
    padding: 14px 0;
    gap: 4px;

    &__title {
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      color: var(--color-gray-30);
    }

    &__text {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }

    &_underline {
      height: 1px;
      width: 100%;
      background: var(--color-fog-40);
      margin-bottom: 14px;
    }
  }

  &_underline {
    height: 1px;
    width: 100%;
    background: var(--color-fog-40);
    margin-top: 14px;
  }

  &_button-group {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;
  }

  @include screen-tablet {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 624px;
    padding: 32px;
    background: #FFFFFF;
  }
}
