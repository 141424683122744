@import "mixins";

.transfer-form {
  &-control {
    //margin-bottom: 24px;

    &_amount {
      //margin-top: 32px;
    }

    &_top {
      margin-top: 24px;
    }
  }

  &-action {
    margin: 8px 0;

    @include screen-tablet {
      margin: 12px 0;
    }
  }
  &-double-action {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    @include screen-tablet {
      margin-top: 24px;
      display: flex;
      flex-direction: row;
    }

    &>* {
      flex: 1;
    }
    &>*:nth-child(1) {
      margin-right: 16px;
    }
  }

  &-hint {
    margin-top: 24px;
    opacity: 0.5;
    font-size: 14px;
  }
}

.transfer-form-control[data-mb-size="1"] {
  margin-top: 0;

  @include screen-tablet {
    margin-top: 16px;
  }
}
