@import "mixins";

.main {
  display: flex;
  flex-direction: column-reverse;
  height: 100%;

  &-sidebar {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    background: var(--sidebar-background);
  }

  &-content {
    flex: 1 1 auto;
    display: block;
    flex-direction: column;
    overflow: auto;
    overflow-x: hidden;

    &-payload {
      display: block;
      //padding: 40px 0;

      &-page {
        flex: 1;
        max-width: 656px;
        margin: 0 auto;

        @include screen-tablet {
          margin-top: 48px;
        }

        @include screen-desktop {
          margin-left: 84px;
          margin-right: 32px;
        }

        @include screen-desktop-extra-large {
          margin-left: 104px;
          margin-right: 64px;
        }
      }

      &-widgets {
        display: none;
        padding: 40px;

        @include screen-desktop {
          display: block;
        }

        &__item {
          margin-bottom: 48px;
          width: 320px;
          display: block;
          padding: 0 16px;
        }
      }

      @include screen-desktop {
        display: flex;
      }
    }

    &-widgets {
      border-top: 1px solid #DFE2EB;
      padding: 48px 16px;
      margin-top: auto;
      background: var(--color-fog-50);

      @include screen-tablet {
        border-top: 1px solid #DFE2EB;
        padding: 48px 80px;
        margin-top: auto;
        background: var(--color-white);
      }
    }

    &-footer {
      padding: 24px 16px;
      background: var(--color-fog-50);

      @include screen-tablet {
        border-top: 1px solid #DFE2EB;
        padding: 24px 80px;
        background: var(--color-white);
      }
    }
  }

  @include screen-tablet {
    &-sidebar {
      margin: 0 calc((100vw - 640px) / 2) 24px;
      border-radius: var(--sidebar-tablet-br);
    }
  }

  @include screen-desktop {
    flex-direction: row;

    &-sidebar {
      margin: 0;
      border-radius: 0;
      flex-direction: column;
      justify-content: initial;
      padding-left: calc((100vw - 1280px) / 2);
    }
  }

  @include screen-desktop-extra-large {
    &-sidebar {
      //width: 368px;
      padding-right: 22px;
      padding-left: calc((100vw - 1578px) / 2);
    }
  }
}

.main-content-payload.map-content-payload {
  .main-content-payload-page {
    max-width: initial;
  }
}

.sidebar-logo {
  cursor: pointer;
  color: var(--color-logo);

  &__small {
    display: none;
    font-size: 53px;
    margin: 50px 16px 54px;
  }

  &__full {
    display: none;
    font-size: 62px;
    margin: 32px 0 44px;
    padding: 10px;
  }

  @include screen-desktop {
    &__small {
      display: inline-block;
    }

    &__full {
      display: none;
    }
  }

  @include screen-desktop-extra-large {
    &__small {
      display: none;
    }

    &__full {
      display: inline-block;
    }
  }
}

.sidebar-content {
  display: none;
  margin-top: auto;
  margin-bottom: 32px;

  @include screen-desktop-extra-large {
    display: block;
  }
}

.sidebar-rates {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  &_title {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: var(--color-black);
  }

  &_link {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: var(--color-blue);

    cursor: pointer;
  }
}

.sidebar-actions {
  display: none;
  border-top: 1px solid #DFE2EB;
  padding: 32px 0;

  @include screen-desktop-extra-large {
    display: block;
  }
}

.sidebar-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;

  @include screen-desktop {
    flex-direction: column;
  }

  @include screen-desktop-extra-large {
    align-items: initial;
    justify-content: initial;
  }
}

.navigation-item {
  height: var(--nav-item-height-xl);
  min-width: var(--nav-item-width);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: var(--nav-item-border-radius);
  cursor: pointer;
  margin: 4px 4px;

  &__icon {
    color: var(--nav-item-icon-color);
    font-size: var(--nav-item-icon-size);
  }

  &__title {
    color: var(--nav-item-title-color);
    font-size: var(--nav-item-title-size);
  }

  &.active {
    background: var(--nav-item-active-bg);
  }

  &.active &__icon,
  &.active &__title {
    color: var(--nav-item-active-color);
  }

  &:hover {
    background: var(--nav-item-active-bg);
  }

  &:hover &__icon,
  &:hover &__title {
    color: var(--color-brand);
  }

  @include screen-tablet {
    height: var(--nav-item-height-md);
    margin: 4px 12px;
  }

  @include screen-desktop {
    margin: 4px 16px;
  }

  @include screen-desktop-extra-large {
    flex-direction: row;
    justify-content: initial;
    padding: 18px;
    margin: 0;

    &__icon {
      margin-right: 18px;
    }

    &__title {
      font-size: 16px;
    }
  }
}

.mobile {
  display: block;

  @include screen-tablet {
    display: none !important;
  }
}

.desktop {
  display: none;

  @include screen-tablet {
    display: block;
  }
}
