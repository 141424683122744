@import "mixins";


.auth-form {
  display: flex;
  flex-direction: column;
  height: 100%;

  &-title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 32px;

    @include screen-tablet {
      font-size: 40px;
      line-height: 40px;
      font-weight: 700;
    }
  }

  &-title-first {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 16px;

    @include screen-tablet {
      font-size: 40px;
      line-height: 40px;
      font-weight: 700;
    }
  }

  &-subtitle {
    text-align: center;
    color: #606A7D;
    width: 428px;
    margin: -8px auto 32px;
    line-height: 24px;
    white-space: pre-line;

    @include screen-tablet {
      margin-top: 0;
      margin-bottom: 56px;
    }

    @media screen and (max-width: 450px) {
      width: 100%;
    }
  }

  &-segments {
    margin-bottom: 56px;
  }

  &-content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }

  &-fields {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    &__item {
      margin-bottom: 16px;

      &_link {
        text-align: center;
      }

      &_toggle {
        display: flex;
        justify-content: space-between;
      }

      //&_checkbox {
      //  margin-top: -8px;
      //}

      @include screen-tablet {
        margin-bottom: 24px;
      }
    }

    &__text {
      margin-top: -8px;
      color: #606A7D;
    }
  }

  &-warning {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    position: relative;

    &_icon {
      width: 56px;
      height: 56px;
      padding: 15px 19px 19px 19px;
      position: absolute;
      top: -26px;
      flex-shrink: 0;
      background: var(--color-red);
      color: var(--color-white);
      border-radius: 50%;

      .icon-lock {
        font-size: 24px;
      }
    }

    &_block {
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 16px;
      padding: 45px 52px 25px 52px;
      background: var(--color-rose);

      &__title {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        color: var(--color-black);

        @media screen and (max-width: 375px) {
          font-size: 14px;
        }
      }
    }

    &_subtitle {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: var(--color-black);

      @media screen and (max-width: 767px) {
        margin-bottom: 32px;
      }

      @media screen and (max-width: 375px) {
        font-size: 14px;
      }
    }

    @media screen and (max-width: 767px) {
      margin-top: 32px;
    }
  }

  &-actions {
    display: flex;
    flex-direction: column;
    margin-top: auto;

    &_mobile-reverse {
      flex-direction: column-reverse;

      @include screen-tablet {
        flex-direction: column;
      }
    }

    &__item {
      margin: 4px 0;

      @include screen-tablet {
        margin: 8px 0;
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &_desktop {

    &-group {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-height: 120px;
      gap: 8px;

      &__item {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        background: var(--color-fog-75);
        border-radius: 12px;
        cursor: pointer;
        transition: background 128ms;
        padding: 12px;
        height: 48px;

        &-category {
          width: 24px;
          height: 24px;
          text-align: center;
          display: flex;
          align-items: center;

          .icon-user {
            font-size: 20px;
            border-radius: 4px;
            color: var(--color-white);
            background: var(--color-blue);
          }

          .icon-card {
            font-size: 20px;
            color: var(--color-blue);
            background: var(--color-white);
          }
        }

        &-title {
          width: 85%;
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;

          @media screen and (max-width: 384px) {
            width: 80%;
          }
        }

        &-icon {
          display: flex;
          align-items: center;
          text-align: center;
          width: 24px;
          height: 24px;
          font-size: 12px;
          padding-left: 10px;

          color: var(--color-gray-30);
        }
      }
    }
  }
}
