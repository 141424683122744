.about-info-modal {
  &-text {
    margin-bottom: 32px;

    &__box {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &-form {
    &__item {
      display: block;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
