@mixin screen-tablet-min {
  @media screen and (max-width: 500px) {
    @content;
  }
}

@mixin screen-tablet {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin screen-tablet-desktop {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin screen-tablet2 {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin screen-desktop {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin screen-desktop-large {
  @media (min-width: 1440px) {
    @content;
  }
}


@mixin screen-desktop-extra-large {
  @media (min-width: 1600px) {
    @content;
  }
}
