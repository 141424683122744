html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: var(--font-family-primary);
  background: var(--background-body);
  color: var(--font-color);
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  line-height: var(--font-line-height);
}

.pulse {
  animation: pulse 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  animation-delay: 0.5s;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.data-popup-height {
  max-width: 80vw;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    min-width: 100%;
  }
}
