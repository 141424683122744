@import "mixins";

.info-list {
  &-item {
    padding: 12px 0;
    border-bottom: 1px solid #DFE2EB;
    display: flex;
    flex-direction: column;

    @include screen-tablet {
      padding: 24px 0;
      border-bottom: 1px solid #DFE2EB;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__title {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;

      min-width: 258px;
      color: var(--color-gray-30);
    }

    &__value {
      display: flex;
      align-items: center;

      font-size: 16px;
      font-weight: 400;
      line-height: 24px;

      @include screen-tablet {
        margin-left: auto;
        display: flex;
        align-items: center;
        text-align: end;
      }

      i {
        margin-right: 8px;
        font-size: 20px;
        opacity: 0.5;
        cursor: pointer;
      }
    }
  }

  &-item-mob {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 12px 0;
    border-bottom: 1px solid #DFE2EB;

    &_info {
      &__title {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;

        color: var(--color-gray-30);

        &-text {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }

    &_icon {
      font-size: 20px;
      opacity: 0.5;
      cursor: pointer;
    }
  }
}
