.pay-list {
  display: flex;
  max-width: 328px;
  width: 100%;
  flex-direction: column;
  gap: 8px;

  &-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    padding: 10px 16px 10px 16px;
    transition: background 256ms;
    border-radius: 12px;
    cursor: pointer;

    &:hover {
      background: var(--color-fog-75);
    }

    &:last-child {
      margin-bottom: 0;
    }

    &-icon {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      font-size: 16px;
      margin-right: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-payload {
      &__title {
      }

      &__desc {
        font-size: 14px;
        color: #606A7D;
      }
    }
  }
}

.pay-list[data-style="1"] {
  max-width: 100%;
}

.mb-5 {
  margin-bottom: 5px;
}
