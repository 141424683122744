.badge {
  background: #0095FF;
  color: #FFFFFF;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-left: 8px;
  min-width: 24px;
  min-height: 24px;
  font-size: 16px;
}