@import "mixins";

.form {
  display: flex;
  flex-direction: column;

  &-field {
    margin-bottom: 16px;

    @include screen-tablet {
      margin-bottom: 24px;
    }
  }
  &-top-indented {
    margin-top: 16px;

    @include screen-tablet {
      margin-top: 24px;
    }
  }
  &-top-indented-double {
    margin-top: 48px;
  }
  &-top-indented-half {
    margin-top: 12px;
  }
  &-indented {
    margin-top: 16px;
    //margin-bottom: 24px;
    @include screen-tablet {
      margin-top: 24px;
    }

    &_text {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }
}

.two-column-form {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  &>* {
    width: calc(50% - 8px);
  }
  app-button {
    margin-top: 8px;
  }
}

.button-indented {
  margin-top: 16px;
}


.range-input {
  background: linear-gradient(to right, #82CFD0 0%, #82CFD0 50%, #fff 50%, #fff 100%);
  border-radius: 8px;
  height: 4px;
  width: 100%;
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;
  accent-color: var(--color-blue);
  position: relative;

  &.invisible {
    position: absolute;
    display: none;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: -2px;
    background: var(--color-blue);
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
  &::after {
    content: "";
    z-index: -1;
    display: block;
    position: absolute;
    right: 0;
    top: -2px;
    background: var(--color-fog-40);
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }

  &.error-state {
    &::before {
      background: var(--color-red);
    }
    &::after {
      background: var(--color-red);
    }
  }
}

.range-right-input {
  &.visible {
    display: block;
  }
  margin-top: 14px;
  display: none;
  background: linear-gradient(to right, var(--color-blue) 0%, var(--color-blue) 100%, var(--color-fog-40) 100%, var(--color-fog-40) 100%);
  accent-color: var(--color-blue);
  pointer-events: none;
}
