@import "mixins";

.auth-page {
  padding: 48px 0;
  display: flex;

  @include screen-tablet {
    padding: 80px 0;
  }

  &-banner {
    flex: 1 0 auto;
    display: none;
    max-width: 484px;
    height: 651px;
    width: 100%;

    @include screen-desktop-large {
      max-width: 568px;
    }

    @include screen-desktop-extra-large {
      max-width: 640px;
    }

    //img {
    //  width: 100%;
    //  height: 100%;
    //}
  }

  &-payload {
    flex: 1 1 auto;

    @include screen-tablet {
      margin-left: 80px;
    }

    &-content {
      max-width: 464px;
      margin: 0 auto;
      height: 100%;
    }
  }

  @include screen-desktop {
    &-banner {
      display: block;
    }
  }
}
