@import "mixins";

.product-item {
  display: flex;
  position: relative;
  align-items: center;
  height: 80px;
  transition: background 256ms;
  border-radius: 12px;
  // padding: 16px 0 16px 0;
  gap: 16px;
  cursor: pointer;

  &-icon {
    min-height: 48px;
    height: 48px;
    min-width: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40%;
    font-size: 24px;
    background: #EBEEFF;
    color: #3752DB;
    position: relative;

    &_pre-approved {
      background: linear-gradient(270deg, #66BFFF 0%, #15A3E0 100%);
      color: var(--color-white);
    }

    img {
      width: 32px;
      height: auto;
    }

    &_star {
      position: absolute;
      top: 4px;
      left: -4px;
      background: #ffffff;
      color: #FFBB33;
      font-size: 10px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-image {
    // width: 48px;
    // height: 48px;
    // margin-right: 16px;
    display: flex;
    max-width: 48px;
    align-items: center;
    position: relative;

    @include screen-tablet {
      display: flex;
      max-width: 63px;
      align-items: center;
      position: relative;
    }

    &__main, &__danger {
      position: absolute;
      top: 5px;
      left: -4px;
      background: #ffffff;
      color: #FFBB33;
      font-size: 10px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__unknown {
      position: absolute;
      top: 9px;
      left: -1px;
      background: var(--color-white);
      color: var(--color-red);
      font-size: 16px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__blocked {
      position: absolute;
      top: 9px;
      left: -4px;
      background: var(--color-white);
      color: var(--color-red);
      font-size: 16px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        background: white;
        font-size: 12px;
        border-radius: 8px;
      }
    }

    &__turnon {
      position: absolute;
      top: 6px;
      left: -4px;
      color: var(--color-white);
      background: var(--color-red);
      font-size: 16px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        background: initial;
      }
    }

    &__danger {
      background: #ffffff;
      color: #E62E4D;
      font-size: 16px;
    }

    &__image {
      min-height: 38px;
      min-width: 48px;
      width: 63px;
      border-radius: 4px;

      @include screen-tablet {
        min-height: 38px;
        min-width: 63px;
        width: 63px;
        border-radius: 4px;
      }
    }

    &__number {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: #ffffff;
      position: relative;
      z-index: 1;
      left: -30px;
      top: 8px;

      &:before {
        display: block;
        content: '';
        height: 4px;
        width: 4px;
        border-radius: 50%;
        background: #ffffff;
        position: absolute;
        left: -6px;
        top: 6px;
      }
    }
  }

  &-info {
    display: flex;
    flex-direction: column;
    height: 56px;
    position: relative;

    &-head {
      display: flex;
      position: relative;
      max-width: 300px;
      height: 24px;
      align-items: center;

      &__title {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        @include screen-tablet {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;

          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      &__desc {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;

        margin-left: 4px;
        color: var(--color-gray-40);

        @include screen-tablet {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;

          margin-left: 4px;
          color: var(--color-gray-40);
        }
      }

      @include screen-tablet-min {
        display: flex;
        position: relative;
        max-width: 202px;
        height: 24px;
        align-items: center;
      }
    }

    &-head_pre-approve {
      &__title {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
      }

      &__desc {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: var(--color-gray-40);
        padding: 4px 0;
      }
    }

    &-amount {
      gap: 4px;
      height: 32px;
      display: flex;
      align-items: center;

      @include screen-tablet {
        gap: 4px;
        height: 32px;
        // padding: 10px 3px;
        display: flex;
        align-items: center;
      }

      &__value {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;

        color: var(--color-black);

        @include screen-tablet {
          font-size: 24px;
          font-weight: 600;
          line-height: 32px;

          color: var(--color-black);
        }
      }

      &__decimal {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;

        color: var(--color-gray-30);

        @include screen-tablet {
          font-size: 24px;
          font-weight: 400;
          line-height: 32px;

          color: var(--color-gray-30);
        }
      }

      &__currency {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;

        color: var(--color-gray-30);

        @include screen-tablet {
          font-size: 24px;
          font-weight: 400;
          line-height: 32px;

          color: var(--color-gray-30);
        }
      }

      &__dots {
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;
        position: relative;
        display: inline-block;

        width: 8px;
        height: 8px;
        margin-right: 6px;
        border-radius: 50%;
        background-color: black;

        &::before {
          content: "";
          position: absolute;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: black;
          top: 2px;
          left: 2px;
        }
      }

      &__action {
        width: 24px;
        height: 24px;
        font-size: 16px;
        padding: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--color-gray-30);
        cursor: pointer;
      }

      &__hidden {
        font-size: 24px;
      }

      &.danger &__value {
        color: #E62E4D;
      }

      &.danger &__decimal {
        color: #E62E4D;
      }

      &.danger &__currency {
        color: #E62E4D;
      }
    }

    &-hint {
      width: fit-content;
      width: -moz-fit-content;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      color: #1C8743;
      border: 1px solid #1C8743;
      border-radius: 20px;
      padding: 4px 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include screen-tablet {
        width: fit-content;
        width: -moz-fit-content;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #1C8743;
        border: 1px solid #1C8743;
        border-radius: 20px;
        padding: 4px 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &-hint-neg {
      width: -moz-fit-content;
      width: fit-content;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      color: var(--color-red);
      border: 1px solid var(--color-red);
      border-radius: 20px;
      padding: 4px 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include screen-tablet {
        width: -moz-fit-content;
        width: fit-content;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: var(--color-red);
        border: 1px solid var(--color-red);
        border-radius: 20px;
        padding: 4px 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &-hint-gray {
      width: -moz-fit-content;
      width: fit-content;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      color: var(--color-achromatic-gray);
      border: 1px solid var(--color-achromatic-gray);
      border-radius: 20px;
      padding: 4px 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include screen-tablet {
        width: -moz-fit-content;
        width: fit-content;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: var(--color-achromatic-gray);
        border: 1px solid var(--color-achromatic-gray);
        border-radius: 20px;
        padding: 4px 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &-hint-green {
      width: -moz-fit-content;
      width: fit-content;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      color: var(--color-green-dark);
      border: 1px solid var(--color-green-dark);
      border-radius: 20px;
      padding: 4px 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include screen-tablet {
        width: -moz-fit-content;
        width: fit-content;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: var(--color-green-dark);
        border: 1px solid var(--color-green-dark);
        border-radius: 20px;
        padding: 4px 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &-hint-orange {
      width: -moz-fit-content;
      width: fit-content;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      color: var(--color-orange);
      border: 1px solid var(--color-orange);
      border-radius: 20px;
      padding: 4px 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include screen-tablet {
        width: -moz-fit-content;
        width: fit-content;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: var(--color-orange);
        border: 1px solid var(--color-orange);
        border-radius: 20px;
        padding: 4px 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &-actions {
    margin-left: auto;
    align-items: center;
    opacity: 0;
    transition: opacity 256ms;
    display: none;

    &-item {
      height: 32px;
      width: 32px;
      margin-left: 16px;
      color: #606A7D;
      font-size: 20px;
      cursor: pointer;

      .icon-eye-open {
        font-size: 22px;
        line-height: 15px;
      }

      .icon-eye-closed {
        font-size: 22px;
        line-height: 18px;
      }

      .icon-star {
        font-size: 20px;
        line-height: 19px;
      }

      .icon-round-plus,
      .icon-edit,
      .icon-moon {
        font-size: 18px;
        line-height: 18px;
      }

      .icon-sun {
        font-size: 24px;
        line-height: 24px;
      }
    }

    &.mobile {
      display: flex;
    }

    &.hover-icon {
      opacity: 1;
    }

    @include screen-tablet {
      display: flex;

      &.mobile {
        display: none;
      }
    }
  }

  &:hover {
    background: var(--color-fog-75);
  }

  &.hover {
    position: relative;
    z-index: 1;
  }

  &.hover::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    transition: background 256ms;
    border-radius: 12px;
    background: var(--color-fog-75);
  }

  &:hover &-actions {
    opacity: 1;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.product-item-actions[display-data="1"] {
  opacity: 1;
}

.additional {
  padding: 16px 0 16px 0;

  @include screen-tablet {
    padding: 16px 0 16px 16px;
  }
}

.product-credit {
  padding: 16px 0 16px 0;
}

.no-additional {
  padding: 16px 0 16px 0;
}

.product-underline {
  height: 1px;
  max-width: 560px;
  margin-left: 64px;
  background: var(--color-fog-40);

  @include screen-tablet {
    height: 1px;
    max-width: 560px;
    margin-left: 80px;
    background: var(--color-fog-40);
  }
}

.product-underline-add {
  height: 1px;
  max-width: 560px;
  margin-left: 0;
  background: var(--color-fog-40);

  @include screen-tablet {
    height: 1px;
    max-width: 560px;
    margin-left: 17px;
    background: var(--color-fog-40);
  }
}

.product-underline-add-skeleton {
  height: 1px;
  max-width: 560px;
  margin-left: 64px;
  background: var(--color-fog-40);
}

.cdk-drag-preview {
  box-sizing: border-box;
  background: var(--color-fog-75);
  opacity: 0.9;
  border-radius: 12px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.20);
}

.product-item-placeholder {
  border: solid 1px var(--color-blue);
  border-radius: 12px;
  height: 80px;
  background: var(--color-blue-dark-light);
  min-height: 60px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.product-item.cdk-drop-list-dragging {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.mr-2 {
  margin-right: 16px;
}
