.theme {

  // Dropdown Menu
  // ********************
  // ********************
  .mat-mdc-menu-panel.mat-mdc-menu-panel {
    border-radius: 12px;
    min-width: 244px;
    padding: 8px;
    box-shadow: none;
  }

  .mat-mdc-menu-content {
    flex-direction: column;
  }

  .mdc-list-item {
    &__primary-text {
      display: flex;
      align-items: center;
      width: 100%;
    }
  }

  // Dialog
  .mat-mdc-dialog-container .mdc-dialog__surface {
    background: none;
    box-shadow: none;
  }

  .partly-hidden.product-item {
    .product-item-info {
      opacity: 0.5;
    }

    .product-item-image {
      img {
        opacity: 0.5;
      }
    }
  }

  .mat-calendar-table-header th {
    font-size: 16px;
    color: var(--color-black);
  }

  .mat-datepicker-content .mat-calendar-next-button {
    color: var(--color-black);
  }

  .mat-datepicker-content .mat-calendar-previous-button {
    color: var(--color-black);
  }

  .mat-datepicker-content .mat-calendar-next-button[disabled] {
    opacity: 0.5 !important;
  }

  .mat-datepicker-content .mat-calendar-previous-button[disabled] {
    opacity: 0.5 !important;
  }

  .mat-datepicker-content .mat-calendar {
    height: auto!important;
  }

  .mat-datepicker-content {
    .mat-datepicker-actions {
      border-top: 1px solid var(--color-fog-50);
      padding: 0;
    }
  }

  .mat-calendar-table-header-divider::after {
    background: var(--color-fog-50);
  }

  .mat-calendar-body-label {
    padding-top: 0!important;
    padding-bottom: 0!important;

    opacity: 0;
  }
}

.theme.theme-light .mat-mdc-icon-button {
  --mdc-icon-button-state-layer-size: 40px;
  --mdc-icon-button-icon-size: 12px;
}
