@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?tjzq72');
  src:  url('fonts/icomoon.eot?tjzq72#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?tjzq72') format('truetype'),
    url('fonts/icomoon.woff?tjzq72') format('woff'),
    url('fonts/icomoon.svg?tjzq72#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-round-check:before {
  content: "\e900";
}
.icon-round-back:before {
  content: "\e901";
}
.icon-round-close:before {
  content: "\e902";
}
.icon-round-enter:before {
  content: "\e903";
}
.icon-round-star:before {
  content: "\e904";
}
.icon-round-plus:before {
  content: "\e905";
}
.icon-round-time:before {
  content: "\e906";
}
.icon-error:before {
  content: "\e907";
}
.icon-clock:before {
  content: "\e908";
}
.icon-chart-pie:before {
  content: "\e909";
}
.icon-round-segment:before {
  content: "\e90a";
}
.icon-briefcase:before {
  content: "\e90b";
}
.icon-sofa:before {
  content: "\e90c";
}
.icon-arrow-enter-right:before {
  content: "\e90d";
}
.icon-arrow-back:before {
  content: "\e90e";
}
.icon-download:before {
  content: "\e90f";
}
.icon-arrow-bold-right:before {
  content: "\e910";
}
.icon-chevron-up:before {
  content: "\e911";
}
.icon-chevron-down:before {
  content: "\e912";
}
.icon-arrow-drop-up:before {
  content: "\e913";
}
.icon-arrow-drop-down:before {
  content: "\e914";
}
.icon-arrow-drop-left:before {
  content: "\e915";
}
.icon-arrow-drop-right:before {
  content: "\e916";
}
.icon-plus:before {
  content: "\e917";
}
.icon-cross:before {
  content: "\e918";
}
.icon-cross-bold:before {
  content: "\e919";
}
.icon-round-attention:before {
  content: "\e91a";
}
.icon-check-bold:before {
  content: "\e91b";
}
.icon-transfer:before {
  content: "\e91c";
}
.icon-exchange:before {
  content: "\e91d";
}
.icon-round-info:before {
  content: "\e91e";
}
.icon-repeat:before {
  content: "\e91f";
}
.icon-swap:before {
  content: "\e920";
}
.icon-reset:before {
  content: "\e921";
}
.icon-assignment:before {
  content: "\e922";
}
.icon-document:before {
  content: "\e923";
}
.icon-documents:before {
  content: "\e924";
}
.icon-catalog:before {
  content: "\e925";
}
.icon-electronic:before {
  content: "\e926";
}
.icon-requisites:before {
  content: "\e927";
}
.icon-atm:before {
  content: "\e928";
}
.icon-bank:before {
  content: "\e929";
}
.icon-bank-2:before {
  content: "\e92a";
}
.icon-home:before {
  content: "\e92b";
}
.icon-key:before {
  content: "\e92c";
}
.icon-blocked:before {
  content: "\e92d";
}
.icon-unlock:before {
  content: "\e92e";
}
.icon-lock:before {
  content: "\e92f";
}
.icon-shoping:before {
  content: "\e930";
}
.icon-taxes:before {
  content: "\e931";
}
.icon-calendar:before {
  content: "\e932";
}
.icon-calendar-empty:before {
  content: "\e933";
}
.icon-card:before {
  content: "\e934";
}
.icon-cards:before {
  content: "\e935";
}
.icon-duplicate:before {
  content: "\e936";
}
.icon-file:before {
  content: "\e937";
}
.icon-charity:before {
  content: "\e938";
}
.icon-coins:before {
  content: "\e939";
}
.icon-courts:before {
  content: "\e93a";
}
.icon-covid:before {
  content: "\e93b";
}
.icon-delete:before {
  content: "\e93c";
}
.icon-edit:before {
  content: "\e93d";
}
.icon-education:before {
  content: "\e93e";
}
.icon-exit:before {
  content: "\e93f";
}
.icon-eye-closed:before {
  content: "\e940";
}
.icon-eye-open:before {
  content: "\e941";
}
.icon-eye-time:before {
  content: "\e942";
}
.icon-sound:before {
  content: "\e943";
}
.icon-wifi:before {
  content: "\e944";
}
.icon-internet:before {
  content: "\e945";
}
.icon-limits:before {
  content: "\e946";
}
.icon-link:before {
  content: "\e947";
}
.icon-link-arrow:before {
  content: "\e948";
}
.icon-location:before {
  content: "\e949";
}
.icon-lotery:before {
  content: "\e94a";
}
.icon-mail:before {
  content: "\e94b";
}
.icon-letter:before {
  content: "\e94c";
}
.icon-insurance:before {
  content: "\e94d";
}
.icon-mass-media:before {
  content: "\e94e";
}
.icon-it-services:before {
  content: "\e94f";
}
.icon-gadge:before {
  content: "\e950";
}
.icon-gadge-lock:before {
  content: "\e951";
}
.icon-more-vertical:before {
  content: "\e952";
}
.icon-more-horizontal:before {
  content: "\e953";
}
.icon-filter:before {
  content: "\e954";
}
.icon-deposit-safe:before {
  content: "\e955";
}
.icon-more-filled:before {
  content: "\e956";
}
.icon-more-empty:before {
  content: "\e957";
}
.icon-notifications-off:before {
  content: "\e958";
}
.icon-notifications-on:before {
  content: "\e959";
}
.icon-notifications:before {
  content: "\e95a";
}
.icon-payment:before {
  content: "\e95b";
}
.icon-phone:before {
  content: "\e95c";
}
.icon-photo:before {
  content: "\e95d";
}
.icon-video:before {
  content: "\e95e";
}
.icon-pig:before {
  content: "\e95f";
}
.icon-real-estate:before {
  content: "\e960";
}
.icon-safety:before {
  content: "\e961";
}
.icon-search:before {
  content: "\e962";
}
.icon-settings:before {
  content: "\e963";
}
.icon-social-services:before {
  content: "\e964";
}
.icon-utilities:before {
  content: "\e965";
}
.icon-moon:before {
  content: "\e966";
}
.icon-sun:before {
  content: "\e967";
}
.icon-star:before {
  content: "\e968";
}
.icon-parking:before {
  content: "\e969";
}
.icon-customs:before {
  content: "\e96a";
}
.icon-car:before {
  content: "\e96b";
}
.icon-transport4:before {
  content: "\e96c";
}
.icon-tourism:before {
  content: "\e96d";
}
.icon-user:before {
  content: "\e96e";
}
.icon-wallet:before {
  content: "\e96f";
}
.icon-like:before {
  content: "\e970";
}
.icon-dislike:before {
  content: "\e971";
}
.icon-social-vk:before {
  content: "\e972";
}
.icon-social-youtube:before {
  content: "\e973";
}
.icon-social-facebook:before {
  content: "\e974";
}
.icon-social-instagram:before {
  content: "\e975";
}
.icon-currency-ruble:before {
  content: "\e976";
}
.icon-currency-funt:before {
  content: "\e977";
}
.icon-currency-euro:before {
  content: "\e978";
}
.icon-currency-dollar:before {
  content: "\e979";
}
.icon-currency-zloty:before {
  content: "\e97a";
}
.icon-currency-byn:before {
  content: "\e97b";
}
.icon-qr:before {
  content: "\e97c";
}
.icon-mobile:before {
  content: "\e97d";
}
.icon-logo-full:before {
  content: "\e97e";
}
.icon-logo-small:before {
  content: "\e97f";
}
.icon-social-ok:before {
  content: "\e980";
}
.icon-DragnDrop:before {
  content: "\e981";
}
.icon-desctop:before {
  content: "\e982";
}
.icon-chat2:before {
  content: "\e983";
}
.icon-cvv:before {
  content: "\e984";
}
.icon-turnon:before {
  content: "\e985";
}
.icon-Check-21:before {
  content: "\e986";
}
.icon-coinsBundle2:before {
  content: "\e987";
}
.icon-Deposit7:before {
  content: "\e988";
}
.icon-Doc21:before {
  content: "\e989";
}
.icon-arrow-right:before {
  content: "\e98a";
}
.icon-credit-payments:before {
  content: "\e98b";
}
.icon-receipt:before {
  content: "\e98c";
}
.icon-substract:before {
  content: "\e98d";
}
.icon-banking:before {
  content: "\e98e";
}
.icon-Tarifs:before {
  content: "\e98f";
}
.icon-icon-change-password:before {
  content: "\e990";
}
.icon-xls:before {
  content: "\e991";
}
.icon-sort-down:before {
  content: "\e992";
}
.icon-sort-up:before {
  content: "\e993";
}
.icon-vector:before {
  content: "\e994";
}
.icon-mark:before {
  content: "\e995";
}
.icon-mountain:before {
  content: "\e996";
}
.icon-rect-right:before {
  content: "\e997";
}
.icon-rect-left:before {
  content: "\e998";
}
.icon-land:before {
  content: "\e999";
}
.icon-vector-rotate:before {
  content: "\e99a";
}
