

[class^="ymaps"][class$="-map"] {
  width: 100% !important;
  height: 100% !important;
}


[class^="ymaps"][class$="-default-cluster"] {
  ymaps {
    color: #000000;
    font-weight: 600;
    font-size: 24px;
    line-height: 48px;
  }
}

.ymaps-2-1-79-placemark-overlay {
  .ymaps-2-1-79-default-cluster {
    width: 70px !important;
    height: 70px !important;
    line-height: 32px !important;
    font-size: 32px !important;
    ymaps {
      font-size: 32px;
      line-height: 69px;
    }
  }
}

[class^="ymaps"][class$="-balloon-pane"] {
  visibility: hidden !important;
  display: none !important;
}
