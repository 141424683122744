@import "mixins";

.page {

  // padding: 16px 16px 48px;

  &.hide-navigation {
    .page-search {
      display: none;
    }
    .app-tabs-list {
      display: none;
    }
  }

  &-search {
    margin-bottom: 32px;
  }

  &-btn-save {
    margin-top: 0;

    @include screen-tablet {
      margin-top: 24px;
    }
  }

  &-group {
    @include screen-tablet {
      display: flex;
      gap: 16px;
      align-items: end;

      &__item {
        width: 100%;
      }
    }
  }

  &-card_control-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    align-self: stretch;

    @include screen-tablet {
      flex-direction: row;
      gap: 20px;
    }

    &_item {
      width: 100%;
    }
  }

  &-back {
    color: #606A7D;
    display: flex;
    align-items: center;
    cursor: pointer;
    // padding: 16px 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;

    @include screen-tablet {
      padding-top: 0;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }

    i {
      font-size: 12px;
      padding: 2px;
      margin-right: 4px;
    }
  }

  &-absent-subtitle {
    margin-top: 12px;
  }

  &-header {
    display: flex;
    padding: 16px;
    align-items: center;

    &-title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 24px;
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;

      &__name {
        margin-right: 8px;
      }
    }

    @include screen-tablet {
      display: none;
    }
  }

  &-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    word-wrap: break-word;
    word-break: break-all;

    &_card {
      margin: 0 !important;
    }

    @include screen-tablet {
      margin-bottom: 48px;
      font-size: 32px;
      font-weight: 600;
      line-height: 40px;
    }

    &__name {
      &-edit {
        display: contents;
        margin-left: 8px;
        color: #606A7D;
        font-size: 18px;
        cursor: pointer;
      }
    }

    &__logo {
      position: relative;
      height: 64px;
      width: auto;
      min-width: 64px;
    }
  }

  &-title-md {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    color: var(--color-black);

    margin: 48px 0 24px 0;
  }

  &-subtitle {
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: var(--color-gray-30);
  }

  &_new {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: var(--color-gray-30);
  }

  &-subtitle-card, &-subtitle-account {
    display: flex;
    height: 24px;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;

    @include screen-tablet {
      margin: 4px 0 24px;
    }

    &_status {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: var(--color-gray-30);
    }
  }

  &-subtitle-card[data-size="1"] {
    padding: 16px 16px;

    @include screen-tablet {
      padding: 0;
    }
  }

  &-subtitle-account[data-size="1"] {
    padding: 0 16px;

    @include screen-tablet {
      padding: 0;
    }
  }

  &-payload {
    width: 100%;
    //margin-top: 24px;

    .consent {
      display: flex;
      width: 100%;
      align-items: center;
      cursor: pointer;

      &__icon {
        min-width: 48px;
        min-height: 48px;
        margin-right: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--color-fog-50);
        border-radius: 24px;
        font-size: 24px;
        color: var(--color-achromatic-gray);
      }

      &__body {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 18px 0;
        border-bottom: 1px solid var(--color-fog-40);
      }

      &__content {
        &-title {
          margin-bottom: 4px;
          font-size: 20px;
          font-weight: 400;
          line-height: 24px;
        }

        &-subtitle {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: var(--color-gray-30);
        }
      }

      &__arrow-icon {
        min-width: 24px;
        min-height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: var(--color-gray-30);
      }
    }
  }

  &-language {
    margin-top: 16px;

    @include screen-tablet {
      margin-top: 24px;
    }
  }

  &-hint {
    opacity: 0.5;
    font-size: 14px;
    margin-top: 8px;

    @include screen-tablet {
      opacity: 0.5;
      font-size: 14px;
      margin-top: 24px;
    }
  }

  &-section {
    &-search {
      display: flex;
      width: 100%;
      gap: 8px;
      padding: 8px 0;
      align-items: center;

      &_input {
        width: 100%;
      }

      &_button-filter {
        display: flex;
        height: 56px;
        padding: 12px 15px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 12px;
        border: 1px solid var(--color-fog-40);
        background: var(--color-white);

        .icon-filter {
          font-size: 20px;
          color: var(--color-gray-30);
        }

        .icon-cross {
          font-size: 14px;
          width: 24px;
          height: 24px;
          padding: 5px;
          text-align: center;
          color: var(--color-gray-30);
        }

        .active-filter {
          position: absolute;
          top: -3px;
          right: 3px;
          color: var(--color-blue-light);
          font-size: 20px;
        }
      }

      @include screen-tablet {
        gap: 16px;
        padding: 28px 0 0;

        &_button-filter {
          padding: 16px;

          .icon-filter {
            font-size: 22px;
          }
        }
      }
    }

    &-title {
      padding: 16px 16px;
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;

      @include screen-tablet {
        padding: 32px 0 16px;
        font-size: 32px;
        line-height: 40px;
      }
    }

    &_select {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      margin: 24px 0 0 0;

      @include screen-tablet {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
        gap: 24px;
        margin: 24px 0 0 0;
      }

      &__item {
        width: 100%;
        min-width: 300px;

        @include screen-tablet {
          min-width: 300px;
        }
      }
    }

    &-converter {
      margin-top: 48px;
    }

    &-payload {
      padding: 32px 0 0 0;

      @include screen-tablet {
        padding: 0;
      }
    }

    &-action {
      margin: 24px 16px 48px 16px;

      @include screen-tablet {
        margin: 24px 0 48px;
      }
    }

    &-info {
      margin-top: 24px;
      opacity: 0.5;
    }
  }

  &-action {
    margin-top: 32px;
  }

  &-product {
    margin-top: 48px;

    &_info {
      display: flex;
      padding: 24px 0 24px 0;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      border-bottom: 1px solid #DFE2EB;

      &__title {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: var(--color-gray-30);
      }

      &__value {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: var(--color-black);
      }
    }
  }

  &-submit {
    width: 100%;
    margin-top: 48px;
  }

  &-catalog_title {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: var(--color-black);

    margin-bottom: 24px;

    @include screen-tablet {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      color: var(--color-black);

      margin-bottom: 16px;
    }
  }

  @include screen-tablet {
    &-catalog_title {
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 40px;
      color: var(--color-black);

      margin-bottom: 40px;
    }
  }

  &-catalog_cards {
    margin-top: 0;
  }

  @include screen-tablet {
    &-catalog_cards {
      margin-top: 16px;
    }
  }

  &-match_title, &-account_title {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    color: var(--color-black);

    margin-bottom: 24px;
  }

  @include screen-tablet {
    &-match_title, &-account_title {
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 40px;
      color: var(--color-black);

      margin-bottom: 48px;
    }
  }

  &-account_info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 24px 0 24px 0;

    &__title {
      display: flex;
      width: 100%;
      padding: 16px 0 16px 0;
      align-items: center;

      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      color: var(--color-black);

      @include screen-tablet {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        color: var(--color-black);
      }
    }

    &__items {
      display: flex;
      width: 100%;
      min-height: 88px;
      align-items: center;
      gap: 16px;
      align-self: stretch;
      cursor: pointer;
      justify-content: space-between;
      position: relative;

      &_info {
        display: flex;
        align-items: center;
        gap: 16px;

        &-icon {
          width: 48px;
          height: 48px;
          display: flex;
          padding: 12px;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          background-color: var(--color-fog-50);
          border-radius: 24px;
          font-size: 18px;

          .icon-file {
            font-size: 24px;
            color: var(--color-achromatic-gray);
          }
        }

        &-text {
          color: var(--color-black);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;

          @include screen-tablet {
            color: var(--color-black);
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }

      &-underline {
        position: absolute;
        bottom: -5px;
        left: 64px;
        right: 0;
        height: 1px;
        background: var(--color-fog-40);
        content: "";
      }
    }
  }

  &-account_button {
    padding-top: 0;
    width: 100%;

    @include screen-tablet {
      padding-top: 24px;
      width: 100%;
    }
  }

  &-match_term {
    margin: 24px 0 0;
  }

  @include screen-tablet {
    &-match_term {
      margin: 24px 0 12px;
    }
  }

  &-match_term__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    &__label {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      color: var(--color-black);
    }

    &__text {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: var(--color-black);
    }
  }

  &-match_switch__block {
    display: flex;
    position: relative;
    width: 100%;
    padding: 24px 0 23px 0;
    justify-content: space-between;
    align-items: flex-start;
    gap: 23px;

    &-text {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: var(--color-black);
    }

    &-underline {
      position: absolute;
      bottom: -5px;
      left: 0;
      height: 1px;
      width: 100%;
      background: var(--color-fog-40);
      content: '';
    }
  }

  &-match_widget {
    margin-top: 32px;

    &__text {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: var(--color-gray-30);
    }
  }

  @include screen-tablet {
    &-match_widget {
      margin-top: 48px;

      &__text {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: var(--color-gray-30);
      }
    }
  }

  &-cat-navigation {
    margin-top: 24px;
  }

  &-catalog-section {
    display: flex;
    // padding: 16px 0;
    flex-direction: column;
    // gap: 16px;
    align-self: stretch;

    &_title {
      display: flex;
      height: 56px;
      padding: 16px 0 16px;

      &__item {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        color: var(--color-black);
      }
    }

    &_text {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      color: var(--color-gray-30);

      padding-bottom: 8px;
    }

    @include screen-tablet {
      &_text {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        color: var(--color-gray-30);

        padding-bottom: 0;
      }
    }

    @include screen-tablet {
      &_text {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        color: var(--color-gray-30);

        padding-bottom: 12px;
      }
    }

    &_info {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: var(--color-gray-30);
    }

    &_submit {
      margin-top: 24px;
    }

    @include screen-tablet {
      &_submit {
        margin-top: 32px;
      }
    }

    &_exchange {
      display: flex;
      flex-direction: column;
      width: 100%;

      &__translation, &__debit {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        &-label {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          padding-right: 5px;
          color: var(--color-gray-30);
        }

        &-amount {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          color: var(--color-blue);
        }
      }
    }
  }

  &-template_title {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    color: var(--color-black);

    @media screen and (max-width: 375px) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  &-update-template {
    width: 100%;

    &_autopay {
      display: flex;
      height: 44px;
      margin-top: 24px;
      justify-content: space-between;

      &__info {
        display: flex;
        flex-direction: column;
        gap: 4px;

        &-title {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: var(--color-black);
        }

        &-subtitle {
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
          color: var(--color-gray-30);
        }
      }
    }

    &_button {
      width: 100%;
      margin-top: 24px;
    }

    &_buttons {
      width: 100%;
      display: flex;
      margin-top: 24px;
      gap: 16px;
      justify-content: space-between;

      &__item {
        width: 50%;

        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }

      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        margin-top: 40px;
      }
    }

    &_auto {
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-top: 48px;

      &__info {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &-title {
          font-size: 20px;
          font-weight: 600;
          line-height: 24px;

          color: var(--color-black);

          @media screen and (max-width: 375px) {
            font-size: 16px;
          }
        }

        &-subtitle {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;

          color: var(--color-gray-30);

          @media screen and (max-width: 375px) {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }

      @media screen and (max-width: 375px) {
        font-size: 16px;
      }

      &__form {
        display: flex;
        flex-direction: column;
        gap: 24px;

        &-control_group {
          display: flex;
          width: 100%;
          flex-wrap: nowrap;
          gap: 24px;
          justify-content: space-between;

          &__item {
            width: 50%;

            &-date {
              &_label {
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                color: var(--color-black);
              }

              &_input {
                margin-top: 8px;
              }
            }

            @media screen and (max-width: 768px) {
              width: 100%;
            }
          }

          @media screen and (max-width: 768px) {
            display: flex;
            flex-direction: column;
          }
        }

        &-control {
          display: flex;
          flex-direction: column;
          width: 48%;

          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }
      }
    }

    @media screen and (max-width: 375px) {
      margin-top: 24px;
      padding: 0 16px;
    }
  }

  &-templates-add {
    display: flex;
    align-items: center;
    height: 72px;
    padding: 0 16px 0 16px;
    transition: background 256ms;
    cursor: pointer;

    &_icon {
      height: 40px;
      width: 40px;
      min-width: 40px;
      min-height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      border-radius: 50%;
      margin-right: 16px;

      background: var(--color-fog-50);
      color: var(--color-gray-30);

      @include screen-tablet {
        height: 48px;
        width: 48px;
        min-width: 48px;
        min-height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        border-radius: 50%;
        margin-right: 16px;

        background: var(--color-fog-50);
        color: var(--color-gray-30);
      }
    }

    &_info {
      &__title {
        font-size: 16px;

        @include screen-tablet {
          font-size: 20px;
        }
      }

      &__description {
        display: flex;

        &-item {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          color: var(--color-gray-30);

          @include screen-tablet {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            color: var(--color-gray-30);
          }
        }
      }
    }

    @include screen-tablet {
      display: flex;
      align-items: center;
      height: 96px;
      padding: 0 16px 0 16px;
      transition: background 256ms;
      cursor: pointer;
    }

    &:hover {
      border-radius: 12px;
      background: var(--color-fog-75);
    }
  }

  &-amount-count-info {
    padding: 0 16px;
    margin-top: 24px;

    &_underline {
      height: 1px;
      background: #DFE2EB;
      bottom: 0;
    }

    &_amount__count {
      display: flex;
      flex-direction: column;
      gap: 4px;
      padding-top: 16px;

      &-title {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: var(--color-gray-30);
      }

      &-amount {
        display: flex;
        align-items: center;
        height: 24px;
        gap: 4px;

        &_value {
          font-size: 20px;
          font-weight: 600;
          line-height: 24px;

          color: var(--color-black);
        }

        &_dec, &_cur {
          font-size: 20px;
          font-weight: 400;
          line-height: 24px;

          color: var(--color-gray-30);
        }
      }

      &-btn {
        padding-top: 24px;
      }

      @media screen and (max-width: 375px) {
        padding: 16px 16px 0 16px;
      }
    }
  }

  &-one-btn-finally {
    display: flex;
    flex-direction: column;
    // gap: 24px;

    &_exchange {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-bottom: 24px;

      &__translation, &__debit {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        &-label {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          padding-right: 5px;
          color: var(--color-gray-30);
        }

        &-amount {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          color: var(--color-blue);
        }
      }
    }
  }

  &-new-product {
    padding: 24px 0 8px 0;
  }
}

.page[data-size="1"] {
  padding: 16px 16px 48px;
}

.page[data-size="2"] {
  padding: 16px 0 48px;
}

.page[data-size="3"] {
  padding: 16px 16px;

  @include screen-tablet {
    padding: 16px 0;
  }
}

.page-payload[pd-size="1"] {
  padding: 8px 0 8px 0;
}

.page-payload[pd-size="2"] {
  padding: 0;
}

.page-payload[pd-size="3"] {
  padding: 0 16px;
}

.page-back[pad-size="1"] {
  padding: 16px 0;
}

.page-back[pad-size="2"] {
  padding: 16px 16px 0;
}

.page-back[pad-size="3"] {
  padding: 16px 16px 0 16px;
}

.page-back[pad-size="4"] {
  padding: 16px 16px 0;

  @include screen-tablet {
    padding: 16px 0 0;
  }
}

.page-back[pad-size="5"] {
  padding: 16px 0 0;
}

.page-back[pad-size="6"] {
  padding: 16px 16px;

  @include screen-tablet {
    padding: 0;
  }
}

.page-back[pad-size="7"] {
  padding: 16px 16px;

  @include screen-tablet {
    padding: 16px 0 16px;
  }
}

.page-title[data-title-size="1"] {
  padding: 16px 0;
}

.page-title[data-title-size="2"] {
  padding: 16px 16px;
}

.page-title[data-title-size="3"] {
  padding: 16px 16px;

  @include screen-tablet {
    padding: 16px 0;
  }
}

.page-title[data-title-size="4"] {
  padding: 16px 16px 0 16px;

  @include screen-tablet {
    padding: 16px 0;
  }
}

.page-title[data-title-size="5"] {
  padding: 0 16px;

  @include screen-tablet {
    padding: 0;
  }
}

.page-title[data-mb-size="1"] {
  margin-bottom: 0;

  @include screen-tablet {
    margin-bottom: 48px;
  }
}

.page-title[data-mb-size="1"] {
  margin-bottom: 0;
}

.page-title-md[data-md-size="1"] {
  padding: 0 16px;
}

.page-subtitle[data-pad-sub-size="1"] {
  padding: 0;
}

.page-subtitle[data-pad-sub-size="2"] {
  padding: 0 16px;
}

.page-subtitle[data-mb-size="1"] {
  margin-bottom: 16px;

  @include screen-tablet {
    margin-bottom: 48px;
  }
}

.page-section[data-pd="1"] {
  padding: 32px 0 16px 0;
}

.page-section[data-pd="2"] {
  padding: 0 16px 48px 16px;

  @include screen-tablet {
    padding-bottom: 48px;
  }
}

.page-section-payload[data-pd="1"] {
  padding: 0 16px 48px 16px;
}

.page-section-payload[data-pd="2"] {
  padding: 0;

  @include screen-tablet {
    padding: 0 0 48px 0;
  }
}

.page-section-title[data-pad-size="1"] {
  padding: 16px 0;

  @include screen-tablet {
    padding: 16px 0 32px 0;
  }
}

.page-section-title[data-pad-size="2"] {
  padding: 24px 16px 16px 16px;

  @include screen-tablet {
    padding: 48px 16px 16px 16px;
  }
}

.page-section-title[data-pad-size="3"] {
  padding: 24px 0 16px 0;

  @include screen-tablet {
    padding: 48px 0 16px 0;
  }
}

.page-template_title[data-temp-pad-size="1"] {
  padding: 0 16px;
}

.page-templates-add[data-height="1"] {
  height: 72px;
}

.page-templates-add_icon[data-size="1"] {
  height: 40px;
  width: 40px;
  min-width: 40px;
  min-height: 40px;
}

.page-templates-add_info__title[data-style="1"] {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.page-templates-add_info__description-item[data-style="1"] {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.page-section-title[data-pd="1"] {
  padding: 32px 0 24px 0;

  @include screen-tablet {
    padding: 48px 0 24px 0;
  }
}

.page-section-title[data-pd="2"] {
  padding: 32px 0 16px 0;

  @include screen-tablet {
    padding: 24px 0 24px 0;
  }
}

.page_buttons[data-container="1"] {
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  gap: 8px;

  margin-top: 8px;

  @include screen-tablet {
    display: flex;
    flex-direction: row;
    padding: 0 16px;
    gap: 8px;

    margin-top: 24px;
  }
}

.setting-page {
  &_title {
    font-size: 24px;
    // padding-left: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    color: var(--color-black);

    @include screen-tablet {
      font-size: 32px;
      // padding-left: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 40px;
      color: var(--color-black);
    }
  }

  @include screen-tablet {
    &_tabs {
      display: flex;
      //padding: 48px 0;
      padding-bottom: 48px;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
    }
  }

  &_tabs {
    display: inline-block;
    width: 100%;
    //padding: 48px 0;
    padding: 16px 0 48px 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    @include screen-tablet {
      display: inline-block;
      width: 100%;
      //padding: 48px 0;
      padding: 24px 0 48px 0;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
    }

    &-btn-filter {
      display: inline-block;
    }

    &__item {
      display: inline-block;
      width: 100%;
      // height: 484px;
      margin-top: 16px;
      // overflow-y: scroll;
    } // TODO: пока комментирую!

    &__item::-webkit-scrollbar {
      width: 0;
    }

    &__item::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    &__item::-webkit-scrollbar-track {
      background-color: transparent;
    }

    /* firefox */
    &__item {
      //scrollbar-width: thin;
      //scrollbar-color: transparent transparent;
      //scrollbar-gutter: stable;
    } // TODO: пока комментирую!
  }
}

.setting-page[data-pd="1"] {
  padding: 0 16px;

  @include screen-tablet {
    padding: 0;
  }
}

.setting-page_title[data-pd-size="1"] {
  padding: 0 16px 24px 16px;
}

.flex-block {
  font-size: 14px;
  line-height: 16px;
  margin-top: 8px;
  color: #606A7D;
  display: flex;
}

.blue-color {
  color: #3752DB;
  font-weight: 500;
}


.select-supertext {
  margin-top: 16px;
  font-size: 16px;
  line-height: 24px;
}


.select-subtext {
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 16px;
  color: #606A7D;
}

.show {
  display: block;
}

.hidden {
  display: none;
}

.section-header {
  padding: 16px 0;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
}

.hint-text {
  color: var(--color-gray-30);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding-bottom: 8px;

  @include screen-tablet {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    padding-bottom: 12px;
  }
}


.pd-size {
  padding: 0 16px;

  @include screen-tablet {
    padding: 0;
  }
}

.pd-size-history {
  padding: 16px 0 0 0;

  @include screen-tablet {
    padding: 24px 0 0 0;
  }
}

.no-justify {
  justify-content: start;
}

.hide-overflow {
  overflow-x: inherit;
}

.half-desktop {
  width: 100%;

  @include screen-tablet {
    width: 50%;
  }
}
