.app-segment-group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background: #EDEFF2;
  height: 48px;
  border-radius: 10px;
}

.app-segment {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: inherit;
  flex: 1;
  cursor: pointer;
  transition: background 128ms;
  margin: 0 4px;
  min-width: 166px;
  color: #606A7D;

  &:hover {
    background: #DFE2EB;
  }

  &.active {
    background: #ffffff;
    box-shadow: 0 3px 12px 5px rgba(155, 155, 159, 0.15);
    color: initial;
  }

  &__title {

  }
}
